import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Input,
  Tooltip,
  Spin,
  Button,
  Icon,
  Modal,
  Table,
} from 'antd'
import { CommonLayout } from '../../../../../src/styled/common-styled'
import { connect } from 'react-redux'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import AuthorizeComponent from '../../../auth/components/AuthorizeComponent'
import * as authActions from 'modules/auth/actions'
import { removeUIState } from 'modules/ui/actions'
import axios from 'axios'
import { TableStyled } from './styledComponent'

const Index = props => {
  const [loading, setLoading] = useState(false)
  const [showGenerate, setShowGenerate] = useState(false)
  const [account, setAccount] = useState('')
  const [generate, setGenerate] = useState('')
  const [expireToken, setExpireToken] = useState('')
  const [dataTable, setDataTable] = useState([])
  const [loadingTable, setLoadingTable] = useState(true)

  const columns = [
    {
      title: (
        <LangContext.Consumer>{i18n => i18n.n.noReport}</LangContext.Consumer>
      ),
      dataIndex: 'key',
      width: 40,
      align: 'center',
      render: (text, record, index) => {
        return <div>{text}</div>
      },
    },
    {
      title: (
        <LangContext.Consumer>
          {i18n => i18n.u.userThatCreatedTheToken}
        </LangContext.Consumer>
      ),
      dataIndex: 'userCreateToken',
      width: 120,
      align: 'center',
    },
    {
      title: (
        <LangContext.Consumer>{i18n => i18n.t.token}</LangContext.Consumer>
      ),
      dataIndex: 'token',
      width: 150,
      align: 'center',
    },
    {
      title: (
        <LangContext.Consumer>{i18n => i18n.s.status}</LangContext.Consumer>
      ),
      dataIndex: 'status',
      width: 150,
      align: 'center',
      render: (text, record) => {
        return (
          <div>
            <label style={{ color: 'green' }}>
              {text == true && (
                <LangContext.Consumer>
                  {i18n => i18n.t.tokenActive}
                </LangContext.Consumer>
              )}
            </label>
            <label style={{ color: 'red' }}>
              {text == false && (
                <LangContext.Consumer>
                  {i18n => i18n.t.tokenExpireds}
                </LangContext.Consumer>
              )}
            </label>
          </div>
        )
      },
    },
    {
      title: (
        <LangContext.Consumer>{i18n => i18n.c.createdAt}</LangContext.Consumer>
      ),
      dataIndex: 'createdAt',
      width: 150,
      align: 'center',
    },
    {
      title: (
        <LangContext.Consumer>{i18n => i18n.e.expiredAt}</LangContext.Consumer>
      ),
      dataIndex: 'expiredAt',
      width: 150,
      align: 'center',
    },
    {
      title: (
        <LangContext.Consumer>{i18n => i18n.c.createdBy}</LangContext.Consumer>
      ),
      dataIndex: 'createdBy',
      width: 150,
      align: 'center',
    },
  ]

  useEffect(() => {
    fetchDataTable()
  }, [])

  const copy = async () => {
    await navigator.clipboard.writeText(generate)
  }

  const showGenerateModal = () => {
    setShowGenerate(true)
  }

  const closeGenerateModal = () => {
    setShowGenerate(false)
  }

  const showMessageErrorModal = msg => {
    Modal.error({
      title: i18n[props.language].g.generateToken,
      content: msg,
    })
  }

  const fetchDataTable = async () => {
    setLoadingTable(true)
    await fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/generate-token-account/dataTable`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${props.auth.accessToken}`,
        },
      },
    )
      .then(response => response.json())
      .then(res => {
        setDataTable(res)
        setLoadingTable(false)
      })
  }

  const getGenerateTokenAPI = account => {
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/generate-token-account`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${props.auth.accessToken}`,
        },
        body: JSON.stringify({
          account: account,
          id: props.profile.id,
        }),
      },
    )
      .then(response => response.json())
      .then(res => {
        if (res.status == 200) {
          fetchDataTable()
          setGenerate(res.token)
          setExpireToken(res.date)
          showGenerateModal()
          setLoading(false)
        } else {
          showMessageErrorModal(res.msg)
          setLoading(false)
        }
      })
  }

  const onRemoveCredentials = () => {
    if (typeof props.profile !== 'undefined') {
      updateUserHistoryLog()
    }
    props.removeCredentials()
    props.removeUIState()
    window.location.replace('/login-by-token')
  }

  const updateUserHistoryLog = () => {
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updatelogonhistory`,
        {
          id: props.profile.user_history_logon_id,
        },
        {
          headers: { Authorization: `Bearer ${props.accessToken}` },
        },
      )
      .then(res => {})
  }

  return (
    <AuthorizeComponent {...props} matching_name="generateToken">
      <Spin spinning={loading}>
        <CommonLayout>
          <Row
            type="flex"
            justify="space-around"
            align="middle"
            gutter={[16, 16]}
          >
            <Col
              span={24}
              style={{
                textAlign: 'center',
              }}
            >
              <p style={{ marginTop: '10vh', fontSize: '24px' }}>
                <LangContext.Consumer>
                  {i18n => i18n.g.generateTokenAccount}
                </LangContext.Consumer>
              </p>
              <Input
                placeholder={i18n[props.language].e.enterAccountToGenerateToken}
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                style={{ width: '50vh', height: '40px' }}
                onChange={e => {
                  setAccount(e.target.value)
                }}
                value={account}
                onPressEnter={() => {
                  if (account != '') {
                    getGenerateTokenAPI(account)
                  }
                }}
                allowClear
              />
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                style={{ width: '50vh', height: '40px' }}
                onClick={() => {
                  getGenerateTokenAPI(account)
                }}
                disabled={account == '' ? true : false}
              >
                <LangContext.Consumer>
                  {i18n => i18n.g.generate}
                </LangContext.Consumer>
              </Button>
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                style={{ width: '50vh', height: '40px' }}
                onClick={() => {
                  onRemoveCredentials()
                }}
              >
                <span style={{ fontSize: '14px' }}>
                  <LangContext.Consumer>
                    {i18n => i18n.l.loginByToken}
                  </LangContext.Consumer>
                </span>
              </Button>
            </Col>
            <Col span={24} style={{ textAlign: 'center', marginTop: '20px' }}>
              <Spin spinning={loadingTable}>
                <TableStyled
                  bordered
                  columns={columns}
                  dataSource={dataTable}
                  scroll={{ y: 300 }}
                />
              </Spin>
            </Col>
          </Row>
        </CommonLayout>
        <Modal
          title={
            <LangContext.Consumer>
              {i18n => i18n.g.generateTokenResult}
            </LangContext.Consumer>
          }
          centered
          visible={showGenerate}
          onCancel={() => closeGenerateModal()}
          footer={null}
        >
          <Row gutter={[24, 24]}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <p style={{ fontSize: '1rem' }}>
                {i18n[props.language].a.account}: {account}
              </p>
              <p style={{ fontSize: '1rem' }}>
                <LangContext.Consumer>
                  {i18n => i18n.t.token}
                </LangContext.Consumer>
                : {generate}{' '}
                <Tooltip title={i18n[props.language].c.copyToken}>
                  <Icon
                    type="copy"
                    onClick={copy}
                    className="copy-generate-token-icon"
                  />
                </Tooltip>
              </p>
              <p style={{ color: 'red', fontSize: '1rem' }}>
                <LangContext.Consumer>
                  {i18n => i18n.t.tokenExpireds}
                </LangContext.Consumer>
                : {expireToken}
              </p>
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                style={{ width: '250px', borderRadius: '15px' }}
                onClick={() => closeGenerateModal()}
              >
                <LangContext.Consumer>
                  {i18n => i18n.c.close}
                </LangContext.Consumer>
              </Button>
            </Col>
          </Row>
        </Modal>
      </Spin>
    </AuthorizeComponent>
  )
}

export default connect(
  ({ auth, auth: { profile }, ui: { language } }) => ({
    auth,
    profile,
    language,
  }),
  {
    removeCredentials: authActions.removeCredentials,
    removeUIState: removeUIState,
  },
)(Index)
