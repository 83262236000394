import { createTypes, createAction } from 'lib/action'

const LOAD_LISTVEHICLE_HONEYTOASTSTREAM = createTypes(
  'loadlistvehicle_honeytoaststream',
  'load',
)
const LOAD_VEHICLE_HONEYTOASTSTREAM = createTypes(
  'vehicle_honeytoaststream',
  'load',
)

const loadListVehicleHoneytoast = {
  request: (data, loading) =>
    createAction(LOAD_LISTVEHICLE_HONEYTOASTSTREAM.REQUEST, { data, loading }),
  success: data =>
    createAction(LOAD_LISTVEHICLE_HONEYTOASTSTREAM.SUCCESS, { data }),
  failure: () => createAction(LOAD_LISTVEHICLE_HONEYTOASTSTREAM.FAILURE),
}

const loadVehicle = {
  request: data =>
    createAction(LOAD_VEHICLE_HONEYTOASTSTREAM.REQUEST, { data }),
  success: data =>
    createAction(LOAD_VEHICLE_HONEYTOASTSTREAM.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_HONEYTOASTSTREAM.FAILURE),
}

export {
  loadListVehicleHoneytoast,
  LOAD_LISTVEHICLE_HONEYTOASTSTREAM,
  loadVehicle,
  LOAD_VEHICLE_HONEYTOASTSTREAM,
}
