import React, { Component } from 'react'
import {
  Row,
  Col,
  Table,
  Button,
  Tooltip,
  Icon,
  Menu,
  Dropdown,
  Modal,
} from 'antd'

import { GeneralStyledContent } from '../../../../styled/common-styled'
import LangContext, { i18n } from 'modules/shared/context/langContext'

const columnStyle = { fontSize: '12px', fontWeight: '400' }
const tagRed = {
  backgroundColor: '#F5F50E',
  color: 'black',
  fontSize: '11px',
  lineHeight: '15px',
  fontWeight: '600',
}

class ListVehicle extends Component {
  state = {
    list_vehicle_id: [],
    visible: false,
    orderBy: 'plate_no',
    orderType: 'asc',
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  hideModal = () => {
    this.setState({
      visible: false,
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    let orderBy = ''
    let orderType = ''
    //console.log("sort:", sorter)

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      this.setState({
        orderBy: 'plate_no',
        orderType: 'desc',
      })
      orderBy = 'plate_no'
      orderType = 'desc'
    } else {
      this.setState({
        orderBy: sorter.columnKey,
        orderType: sorter.order == 'descend' ? 'desc' : 'asc',
      })
      orderBy = sorter.columnKey
      orderType = sorter.order == 'descend' ? 'desc' : 'asc'
    }

    this.props.loadListVehicleHoneytoast(
      {
        list_vehicle_id: this.state.list_vehicle_id,
        orderBy: orderBy,
        orderType: orderType,
        selectedDivision: this.props.selectedDivision,
        selectedDepartment: this.props.selectedDepartment,
        selectedSection: this.props.selectedSection,
        selectedPlant: this.props.selectedPlant,
      },
      true,
    )
  }

  componentDidMount() {
    this.props.loadListVehicleHoneytoast(
      {
        ...this.state,
        selectedDivision: this.props.selectedDivision,
        selectedDepartment: this.props.selectedDepartment,
        selectedSection: this.props.selectedSection,
        selectedPlant: this.props.selectedPlant,
      },
      true,
    )
    this.loadDataInterval = setInterval(async () => {
      this.props.loadListVehicleHoneytoast(
        {
          ...this.state,
          selectedDivision: this.props.selectedDivision,
          selectedDepartment: this.props.selectedDepartment,
          selectedSection: this.props.selectedSection,
          selectedPlant: this.props.selectedPlant,
        },
        true,
      )
    }, 1000 * 60 * 1)
  }

  componentWillUnmount() {
    clearInterval(this.loadDataInterval)
  }

  render() {
    const { vehicle_select, allimei, vehicleListHoneytoast } = this.props
    // console.log('allimeiii', allimei)
    let newArrTable = []
    if (allimei != null) {
      vehicleListHoneytoast.map(item => {
        if (allimei.includes(item.hardware_id)) {
          newArrTable.push({ ...item, status: 1 })
        } else {
          newArrTable.push({ ...item, status: 0 })
        }
      })
      //   console.log('dataTable', newArrTable)
      //   allimei.map(el =>
      //     vehicleListHoneytoast.some(f => {
      //       if (f.hardware_id === el) {
      //         newArrTable = [{ ...vehicleListHoneytoast, status: 1 }]
      //       } else {
      //         newArrTable = [{ ...vehicleListHoneytoast, status: 0 }]
      //       }
      //     }),
      //   )
      //   console.log('dataTable', newArrTable)
    }

    const columns = [
      {
        title: <div className="regular-bold-14">#</div>,
        dataIndex: 'row_number',
        width: '10%',
        className: 'column-text-center',
        onCell: (record, rowIndex) => ({
          onClick: () => {
            this.props.loadVehicleSelect(record.id, record.plate_no)
          },
        }),
        render(text, record, index) {
          return {
            props: {
              style:
                vehicle_select.indexOf(record.id) != -1 ? tagRed : columnStyle,
            },
            children: <div style={{ fontSize: '10px' }}>{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.p.plateNo}
            </LangContext.Consumer>
          </div>
        ),
        dataIndex: 'plate_no',
        width: '40%',
        key: 'plate_no',
        sorter: false,
        onCell: (record, rowIndex) => ({
          onClick: () => {
            this.props.loadVehicleSelect(record.id, record.plate_no)
          },
        }),
        render(text, record, index) {
          return {
            props: {
              style:
                vehicle_select.indexOf(record.id) != -1 ? tagRed : columnStyle,
            },
            children: <div style={{ fontSize: '11px' }}>{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.s.statusVehicleVideo}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'status',
        width: '20%',
        onCell: (record, rowIndex) => ({
          onClick: () => {
            this.props.loadVehicleSelect(record.id, record.plate_no)
          },
        }),
        render(text, record, index) {
          let color = ''
          let tooltipMessage = ''
          if (record.vehicle_status == 'running') {
            color = '#00BF06'
            tooltipMessage = (
              <LangContext.Consumer>
                {i18n => i18n.r.running}
              </LangContext.Consumer>
            )
          } else if (record.vehicle_status == 'stop-with-engine-on') {
            color = '#ffd619'
            tooltipMessage = (
              <LangContext.Consumer>
                {i18n => i18n.s.stopWithEngineOn}
              </LangContext.Consumer>
            )
          } else if (record.vehicle_status == 'stop-with-engine-off') {
            color = '#c91c1c'
            tooltipMessage = (
              <LangContext.Consumer>
                {i18n => i18n.e.engineOff}
              </LangContext.Consumer>
            )
          } else if (record.vehicle_status == 'connection-error') {
            color = '#848484'
            tooltipMessage = (
              <LangContext.Consumer>
                {i18n => i18n.l.lostConnectio}
              </LangContext.Consumer>
            )
          } else {
            color = 'blue'
            tooltipMessage = (
              <LangContext.Consumer>
                {i18n => i18n.l.lostConnectio}
              </LangContext.Consumer>
            )
          }

          return {
            props: {
              style:
                vehicle_select.indexOf(record.id) != -1 ? tagRed : columnStyle,
            },
            children: (
              <Tooltip title={tooltipMessage} placement="left">
                <Icon
                  type="car"
                  theme="twoTone"
                  twoToneColor={color}
                  style={{ fontSize: '16px' }}
                />
              </Tooltip>
            ),
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.vehicleContract.gpsVendor}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'vendor_name',
        width: '30%',
        onCell: (record, rowIndex) => ({
          onClick: () => {
            this.props.loadVehicleSelect(record.id, record.plate_no)
          },
        }),
        render(text, record, index) {
          return {
            props: {
              style:
                vehicle_select.indexOf(record.id) != -1 ? tagRed : columnStyle,
            },
            children: <div style={{ fontSize: '11px' }}>{text}</div>,
          }
        },
      },
    ]

    return (
      <div style={{ height: `${window.innerHeight - 140}px` }}>
        <Table
          bordered
          size="small"
          pagination={false}
          columns={columns}
          rowClassName={(record, index) => {
            let returnVal = ''

            if (record.drum_direction != null) {
              if (record.drum_direction == 1) {
                returnVal = 'table-cement'
              }
            }
            return returnVal
          }}
          rowKey={record => record.id}
          dataSource={vehicleListHoneytoast}
          loading={this.props.videoLoading}
          // onChange={this.handleTableChange}
        />
      </div>
    )
  }
}

export default ListVehicle
