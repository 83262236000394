import { Modal, Form, Input, Button, Row, Col, Select, Icon } from 'antd'
import React, { useState, useEffect } from 'react'
import LangContext from 'modules/shared/context/langContext'

const { Option } = Select
const ModalConfirmPolygon = ({
    showModal,
    onClose,
    selectTools,
    handleSelectTool,
    handleSavePolygon,
    handleUpdatePolygon,
    isMenuMapToolsCLick,
    editValue,
}) => {
    const [routeName, setRouteName] = useState('')
    const [getNote, setGetNote] = useState('')
    const [routeType, setRouteType] = useState('')
    useEffect(() => {
        setRouteType(selectTools)
    }, [selectTools])

    useEffect(() => {
        setRouteName(editValue.route_name)
        setGetNote(editValue.remark)
    }, [editValue])

    return (
        <>
            <Modal
                title={
                    <div style={{ textAlign: 'center' }}>
                        <LangContext.Consumer>
                            {i18n => i18n.p.pleaseFillRouteName}
                        </LangContext.Consumer>
                    </div>
                }
                centered
                visible={showModal}
                onCancel={() => {
                    setRouteName(editValue.route_name)
                    setGetNote(editValue.remark)
                    setRouteType(selectTools)
                    onClose()
                }}
                width={450}
                footer={false}
            >
                <Form
                // onSubmit={handleSubmit}
                >
                    <Row>
                        <Col span={16} offset={4} align="left">
                            <label style={{ color: '#F53C56' }}>
                                <LangContext.Consumer>
                                    {i18n => i18n.s.specifyName}
                                </LangContext.Consumer>{' '}
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                        </Col>
                        <Col span={16} offset={4} align="center">
                            <Form.Item>
                                <LangContext.Consumer>
                                    {i18n => (
                                        <Input
                                            placeholder={i18n.p.pleaseFillYourRouteName}
                                            style={{ height: '50px', borderRadius: '10px' }}
                                            onChange={e => {
                                                setRouteName(e.target.value)
                                                // onChangeNameRoute(e)
                                            }}
                                            value={routeName}
                                            maxLength={50}
                                        />
                                    )}
                                </LangContext.Consumer>
                            </Form.Item>
                        </Col>
                        <Col span={16} offset={4} align="left">
                            <label style={{ color: '#F53C56' }}>
                                <LangContext.Consumer>
                                    {i18n => i18n.c.chooseRouteType}
                                </LangContext.Consumer>
                            </label>
                        </Col>
                        <Col span={16} offset={4} align="center">
                            <Form.Item>
                                <Select
                                    onChange={e => {
                                        // handleSelectTool(e)
                                        setRouteType(e)
                                    }}
                                    placeholder={
                                        <LangContext.Consumer>
                                            {i18n => i18n.c.chooseYourRoute}
                                        </LangContext.Consumer>
                                    }
                                    className="select-style-route-type"
                                    value={`${routeType}`}
                                >
                                    <Option value="1">
                                        <Icon type="highlight" style={{ color: '#FFBA00' }} />{' '}
                                        <LangContext.Consumer>
                                            {i18n => i18n.d.drawSmallTruckRoute}
                                        </LangContext.Consumer>
                                    </Option>
                                    <Option value="2">
                                        <Icon type="highlight" style={{ color: '#F53C56' }} />{' '}
                                        <LangContext.Consumer>
                                            {i18n => i18n.d.drawSmallTruckRouteDoNotEnter}
                                        </LangContext.Consumer>
                                    </Option>
                                    <Option value="3">
                                        <Icon type="highlight" style={{ color: '#FD3DB5' }} />{' '}
                                        <LangContext.Consumer>
                                            {i18n => i18n.d.drawBigTruckRoute}
                                        </LangContext.Consumer>
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={16} offset={4} align="left">
                            <label style={{ color: '#F53C56' }}>
                                <LangContext.Consumer>
                                    {i18n => i18n.n.note}
                                </LangContext.Consumer>
                            </label>
                        </Col>
                        <Col span={16} offset={4} align="center">
                            <Form.Item>
                                <LangContext.Consumer>
                                    {i18n => (
                                        <Input
                                            placeholder={i18n.f.fillYourNote}
                                            style={{ height: '50px', borderRadius: '10px' }}
                                            onChange={e => {
                                                setGetNote(e.target.value)
                                                // onChangeRemark(e)
                                            }}
                                            value={getNote}
                                            maxLength={80}
                                        />
                                    )}
                                </LangContext.Consumer>
                            </Form.Item>
                        </Col>
                        <Col span={16} offset={4} align="center">
                            <Form.Item>
                                {isMenuMapToolsCLick != 'updateRoute' ? (
                                    <Button
                                        type="primary"
                                        block
                                        style={{ borderRadius: '30px', height: '40px' }}
                                        onClick={() => {
                                            handleSavePolygon(getNote, routeName, routeType)
                                            setGetNote('')
                                            setRouteName('')
                                            setRouteType('')
                                        }}
                                        disabled={routeName == '' ? true : false}
                                    >
                                        <LangContext.Consumer>
                                            {i18n => i18n.s.save}
                                        </LangContext.Consumer>
                                    </Button>
                                ) : (
                                    <Button
                                        type="primary"
                                        block
                                        style={{ borderRadius: '30px', height: '40px' }}
                                        onClick={() => {
                                            handleUpdatePolygon(getNote, routeName, routeType)
                                            setGetNote('')
                                            setRouteName('')
                                            setRouteType('')
                                        }}
                                        disabled={routeName == '' ? true : false}
                                    >
                                        <LangContext.Consumer>
                                            {i18n => i18n.s.save}
                                        </LangContext.Consumer>
                                    </Button>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}

export default ModalConfirmPolygon
