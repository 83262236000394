import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'
import { Row, Col, Table, Button, Spin } from 'antd'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import Iframe from 'react-iframe'
import Fullscreen from 'react-full-screen'
import Filter from './Filter'
import ListVehicle from './ListVehicle'
import * as actions from '../actions'
import moment from 'moment'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import AuthorizeComponent from './../../../auth/components/AuthorizeComponent'

const columnStyle = { fontSize: '12px', fontWeight: '400' }

class Index extends Component {
  state = {
    isFull: false,
    visible: false,
    imei: '',
    vehicle_select: [],
    plateno_select: [],
    windowNum: 1,
    division: [],
    department: [],
    section: [],
    plant: [],
    selectedDivision: [],
    selectedDepartment: [],
    selectedSection: [],
    selectedPlant: [],
    selectedDivisionTemp: [],
    selectedDepartmentTemp: [],
    selectedSectionTemp: [],
    selectedPlantTemp: [],
    divisionLoading: false,
    departmentLoading: false,
    sectionLoading: false,
    plantLoading: false,
    // url: "https://login02.terminusfleet.com/mdvr/honeytoast.php?",
    url: process.env.REACT_APP_API_MDVR_TERMINUS,
    iFrameHeight: window.innerHeight - 150,
    iframeLoading1: true,
    iframeLoading2: true,
    iframeLoading3: true,
    iframeLoading4: true,
    iframeLoading5: true,
    iframeLoading6: true,
    idprocess: 'web' + new Date().getTime(),
    allimei: null,
    check_load_vihicle: false,
  }

  ws = new WebSocket(
    `wss://${process.env.REACT_APP_WEBSOCKET}/ws?idprocess=` +
      this.state.idprocess,
  )

  componentDidMount() {
    this.ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      //   console.log('connected')
    }

    this.ws.onmessage = evt => {
      // listen to data sent from the websocket server
      // const message = JSON.parse(evt.data)
      // console.log("response message:", evt.data);
      var messages = evt.data.split('\n')
      //   console.log('messages', messages)
      for (var i = 0; i < messages.length; i++) {
        let obj = JSON.parse(messages[i])
        if (obj.Idprocess == '' && obj.Idconnect == '') {
          if (this.state.check_load_vihicle === false) {
            // console.log('allconneciton', obj.Allconnection)
            // this.loadListVehicleHoneytoast(obj.Allconnection)
            this.setState(
              {
                check_load_vihicle: true,
                allimei: obj.Allconnection,
              },
              //   () => console.log('allimieww', this.state.allimei),
            )
          }
        } else {
          //console.log("data:", JSON.parse(obj.Data))
          let data = JSON.parse(obj.Data)
          if (data.type == 'get_list_playback_video') {
            let newlistFiles = []
            let d = data.duration_time
            let start_time = ''
            // console.log("length:", d.length);
            for (let i = 0; i < d.length; i++) {
              if (i == 0) start_time = String(d[i].date + ' ' + d[i].start_time) //" 12:00:00"
              newlistFiles.push({
                id: i + 1,
                start: String(d[i].date + ' ' + d[i].start_time),
                end: String(d[i].date + ' ' + d[i].end_time),
              })
            }
            // console.log("customTime:", start_time);
            // console.log("items:", newlistFiles);
            if (this.state.change_camera === true) {
              this.setState({
                items: newlistFiles,
                // customTime: { marker: start_time },
                date_min: moment(start_time).format('YYYY-MM-DD') + ' 00:00:00',
                date_max: moment(start_time).format('YYYY-MM-DD') + ' 23:59:59',
              })
            } else {
              this.setState({
                filedownloading: false,
                items: newlistFiles,
                customTime: { marker: start_time },
                date_min: moment(start_time).format('YYYY-MM-DD') + ' 00:00:00',
                date_max: moment(start_time).format('YYYY-MM-DD') + ' 23:59:59',
              })
            }

            if (this.state.change_camera === true) {
              let timeCurrent = moment(this.state.datetime_select)
                .add(this.state.playedSeconds, 'seconds')
                .format('YYYY-MM-DD HH:mm:ss')
              // console.log(
              //     "timecurChangeCamera",
              //     this.state.datetime_select,
              //     timeCurrent
              // );
              this.setState({
                change_camera: false,
                customTime: { marker: timeCurrent },
                datetime_select: timeCurrent,
              })
              const data = JSON.stringify({
                type: 'playback_video',
                camera_source: this.state.camera_potion,
                datetime_select: timeCurrent,
              })
              const message = {
                Idconnect: this.state.idconnect,
                Idprocess: this.state.idprocess,
                Data: data,
              }
              // console.log("change camera:", JSON.stringify(message));
              this.ws.send(JSON.stringify(message))
            }
          } else if (data.type == 'playback_video') {
            var v = moment().format('YYYYMMDDh:mm:ss.SSS')
            // console.log('link_streaming:', data.link_streaming, data)
            if (
              data.link_streaming != 'No video streaming' &&
              data.link_streaming != undefined
            ) {
              // console.log(
              //     "https://stream.terminusfleet.com/hnt/" +
              //     obj.Idprocess +
              //     "/playlist.m3u8?" +
              //     v
              // );
              this.setState({
                filedownloading: false,
                idconnect: obj.Idprocess,
                link_streaming:
                  'https://stream.terminusfleet.com/hnt/' +
                  obj.Idprocess +
                  '/playlist.m3u8?' +
                  v, //data.link_streaming,
              })
            } else {
              if (data.link_streaming != undefined) {
                this.setState({
                  filedownloading: false,
                  playing: false,
                  link_streaming: null,
                })
                this.fileNotStream()
              } else {
                // this.setState({
                //     link_streaming:
                //   "https://stream.terminusfleet.com/hnt/" +
                //   obj.Idprocess +
                //   "/playlist.m3u8?" +v,
                //   playing: false,
                //   });
              }
            }
          }
        }
      }
    }

    this.ws.onclose = () => {
      // automatically try to reconnect on connection loss
      // console.log("disconnected");
    }

    this.loadDivision()
  }

  hideSpinner1 = () => {
    this.setState({
      iframeLoading1: false,
    })
  }

  hideSpinner2 = () => {
    this.setState({
      iframeLoading2: false,
    })
  }

  hideSpinner3 = () => {
    this.setState({
      iframeLoading3: false,
    })
  }

  hideSpinner4 = () => {
    this.setState({
      iframeLoading4: false,
    })
  }

  hideSpinner5 = () => {
    this.setState({
      iframeLoading5: false,
    })
  }

  hideSpinner6 = () => {
    this.setState({
      iframeLoading6: false,
    })
  }

  goFull = () => {
    this.setState({
      isFull: true,
      iFrameHeight: window.innerHeight + 110,
    })
  }

  onchangeFullscreen = isFull => {
    if (isFull) {
      this.setState({
        isFull,
      })
    } else {
      this.setState({
        isFull,
        iFrameHeight: window.innerHeight - 150,
      })
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
      selectedDivisionTemp: this.state.selectedDivision,
      selectedDepartmentTemp: this.state.selectedDepartment,
      selectedSectionTemp: this.state.selectedSection,
      selectedPlantTemp: this.state.selectedPlant,
    })
  }

  handleOk = (values, loading) => {
    this.loadListVehicleHoneytoast(values, loading)
    this.setState({ visible: false })
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      selectedDivision: this.state.selectedDivisionTemp,
      selectedDepartment: this.state.selectedDepartmentTemp,
      selectedSection: this.state.selectedSectionTemp,
      selectedPlant: this.state.selectedPlantTemp,
    })
  }

  loadListVehicleHoneytoast = (values, loading) => {
    this.props.loadListVehicleHoneytoast(
      {
        ...values,
        selectedDivision: this.state.selectedDivision,
        selectedDepartment: this.state.selectedDepartment,
        selectedSection: this.state.selectedSection,
        selectedPlant: this.state.selectedPlant,
      },
      loading,
    )
  }

  loadVehicle = () => {
    this.props.loadVehicle({
      selectedDivision: this.state.selectedDivision,
      selectedDepartment: this.state.selectedDepartment,
      selectedSection: this.state.selectedSection,
      selectedPlant: this.state.selectedPlant,
    })
  }

  loadVehicleSelect = (imei, plate_no) => {
    let vh = [...this.state.vehicle_select]
    let plateno = [...this.state.plateno_select]

    if (this.state.vehicle_select.indexOf(imei) == -1) {
      let i = vh.length
      let count = 1

      if (i == this.state.windowNum) {
        vh.pop()
        vh.unshift(imei)
        plateno.pop()
        plateno.unshift(plate_no)
      } else if (i < this.state.windowNum) {
        vh.push(imei)
        plateno.push(plate_no)
      }

      this.setState({
        vehicle_select: vh,
        plateno_select: plateno,
      })
    } else {
      var vh_filter = vh.filter(function(value, index, arr) {
        return value != imei
      })

      var plate_no_filter = plateno.filter(function(value, index, arr) {
        return value != plate_no
      })

      this.setState({
        vehicle_select: vh_filter,
        plateno_select: plate_no_filter,
      })
    }
  }

  setWindowNum = (window_nums, e) => {
    let vh = [...this.state.vehicle_select]
    let plateno = [...this.state.plateno_select]

    let new_vh = []
    let new_plateno = []
    let i = 0
    if (vh.length > window_nums) {
      for (i = 0; i < window_nums; i++) {
        new_vh[i] = vh[i]
        new_plateno[i] = plateno[i]
      }
    } else {
      new_vh = vh
      new_plateno = plateno
    }

    this.setState({
      windowNum: window_nums,
      vehicle_select: new_vh,
      plateno_select: new_plateno,
      iframeLoading1: true,
      iframeLoading2: true,
      iframeLoading3: true,
      iframeLoading4: true,
      iframeLoading5: true,
      iframeLoading6: true,
    })
  }

  getLink = imei => {
    if (typeof imei === 'undefined' || imei === '') return 'honeytoast.php'
    let rec = this.props.honeytoaststream.vehicleListHoneytoast.filter(
      x => x.id === imei,
    )
    if (rec.length > 0) {
      return typeof rec[0].target_link === 'undefined'
        ? 'honeytoast.php'
        : rec[0].target_link_video_realtime
    }
    return 'honeytoast.php'
  }

  loadDepartment = cb => {
    let self = this
    let departmentVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      departmentVisibility = orgChart.departments.map(ele => ele)
    }
    this.setState({ departmentLoading: true })
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdepartment`, {
      //fetch(`http://booking-api-88:8080/api/getconcreteshippinginfo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        department_visibility: departmentVisibility,
        division: this.state.selectedDivision,
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            departmentLoading: false,
            department: data,
            // selectedSection: [],
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadSection = cb => {
    let self = this
    let sectionVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }
    this.setState({ sectionLoading: true })
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getsection`, {
      //fetch(`http://booking-api-88:8080/api/getconcreteshippinginfo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        section_visibility: sectionVisibility,
        department: this.state.selectedDepartment,
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            section: data,
            sectionLoading: false,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadDivision = cb => {
    let self = this
    let divisionVisibility = []

    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      divisionVisibility = orgChart.divisions.map(ele => ele.divisionNo)
    }
    this.setState({ divisionLoading: true })
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdivision`, {
      //fetch(`http://booking-api-88:8080/api/getconcreteshippinginfo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        division_visibility: divisionVisibility,
        comp: [2, 3, 4, 5, 6],
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            divisionLoading: false,
            division: data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadPlant = cb => {
    let self = this
    let plantVisibility = []
    let sectionVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }
    plantVisibility = self.props.auth.profile.location_visibility
    this.setState({ plantLoading: true })
    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getplantforconcretedashboard`,
      {
        //fetch(`http://booking-api-88:8080/api/getconcreteshippinginfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          location_visibility: plantVisibility,
          section_visibility: sectionVisibility,
          section: this.state.selectedSection,
          relate_company: `${this.props.auth.profile.relate_company}`,
        }),
      },
    )
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            plant: data,
            plantLoading: false,
          },
          () => {
            this.loadVehicle()
            return typeof cb != 'undefined' && cb()
          },
        )
      })
  }

  handleDivisionChange = val => {
    if (this.state.selectedDivision.length > val.length)
      this.setState({
        selectedDepartment: [],
        selectedSection: [],
        selectedPlant: [],
        getIdPlant: [],
      })

    this.setState({ selectedDivision: val }, () => {
      this.loadVehicle()
      this.loadDepartment(this.loadSection(this.loadPlant))
    })
  }

  handleDepartmentChange = val => {
    if (this.state.selectedDepartment.length > val.length)
      this.setState({ selectedSection: [], selectedPlant: [], getIdPlant: [] })

    this.setState({ selectedDepartment: val }, () => {
      this.loadVehicle()
      this.loadSection(() => {
        this.loadPlant()
      })
    })
  }

  handleSectionChange = val => {
    if (this.state.selectedSection.length > val.length)
      this.setState({ selectedPlant: [], getIdPlant: [] })

    this.setState({ selectedSection: val }, () => {
      this.loadVehicle()
      this.loadPlant()
    })
  }

  handlePlantChange = (value, key) => {
    let getKey = key.map(item => Number(item.key))
    this.setState(
      { selectedPlant: getKey, getIdPlant: value },
      this.loadVehicle,
    )
  }

  render() {
    const {
      vehicleLists,
      vehicleListHoneytoast,
      videoLoading,
    } = this.props.honeytoaststream

    // console.log("vehicle_list:", this.state.vehicle_select);
    // console.log("plate_list:", this.state.plateno_select);

    let vehList = this.state.vehicle_select
    let platenoList = this.state.plateno_select
    let iframe = ''
    let i
    for (i = 0; i < this.state.windowNum; i++) {
      if (this.state.windowNum == 1) {
        if (this.state.vehicle_select.length != 0) {
          let endpoint = this.getLink(vehList[0])
          iframe = (
            <Row>
              <Col span={24}>
                <Spin spinning={this.state.iframeLoading1}>
                  <Iframe
                    url={
                      //   this.state.url +
                      endpoint
                      //   '?imei=' +
                      //   vehList[0] +
                      //   '&plate_no=' +
                      //   platenoList[0]
                    }
                    onLoad={this.hideSpinner1}
                    width="100%"
                    height={this.state.iFrameHeight + 'px'}
                  />
                </Spin>
              </Col>
            </Row>
          )
        } else {
          let endpoint = this.getLink('')
          iframe = (
            <Row>
              <Col span={24}>
                <Spin spinning={this.state.iframeLoading2}>
                  <Iframe
                    url={this.state.url + endpoint + '?imei=&plate_no'}
                    onLoad={this.hideSpinner2}
                    width="100%"
                    height={this.state.iFrameHeight + 'px'}
                  />
                </Spin>
              </Col>
            </Row>
          )
        }
      } else if (this.state.windowNum == 4) {
        iframe = (
          <div>
            <Row>
              <Col span={12}>
                <Spin spinning={this.state.iframeLoading3}>
                  <Iframe
                    url={
                      //   this.state.url +
                      this.getLink(vehList[0])
                      //   '?imei=' +
                      //   (typeof vehList[0] !== 'undefined' ? vehList[0] : '') +
                      //   '&plate_no=' +
                      //   (typeof platenoList[0] !== 'undefined'
                      //     ? platenoList[0]
                      //     : '')
                    }
                    width="100%"
                    height={this.state.iFrameHeight / 2 + 'px'}
                    scrolling="no"
                    onLoad={this.hideSpinner3}
                  />
                </Spin>
              </Col>
              <Col span={12}>
                <Spin spinning={this.state.iframeLoading4}>
                  <Iframe
                    url={
                      //   this.state.url +
                      this.getLink(vehList[1])
                      //   '?imei=' +
                      //   (typeof vehList[1] !== 'undefined' ? vehList[1] : '') +
                      //   '&plate_no=' +
                      //   (typeof platenoList[1] !== 'undefined'
                      //     ? platenoList[1]
                      //     : '')
                    }
                    width="100%"
                    height={this.state.iFrameHeight / 2 + 'px'}
                    scrolling="no"
                    onLoad={this.hideSpinner4}
                  />
                </Spin>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Spin spinning={this.state.iframeLoading5}>
                  <Iframe
                    url={
                      //   this.state.url +
                      this.getLink(vehList[2])
                      //   '?imei=' +
                      //   (typeof vehList[2] !== 'undefined' ? vehList[2] : '') +
                      //   '&plate_no=' +
                      //   (typeof platenoList[2] !== 'undefined'
                      //     ? platenoList[2]
                      //     : '')
                    }
                    width="100%"
                    height={this.state.iFrameHeight / 2 + 'px'}
                    scrolling="no"
                    onLoad={this.hideSpinner5}
                  />
                </Spin>
              </Col>
              <Col span={12}>
                <Spin spinning={this.state.iframeLoading6}>
                  <Iframe
                    url={
                      //   this.state.url +
                      this.getLink(vehList[3])
                      //   '?imei=' +
                      //   (typeof vehList[3] !== 'undefined' ? vehList[3] : '') +
                      //   '&plate_no=' +
                      //   (typeof platenoList[3] !== 'undefined'
                      //     ? platenoList[3]
                      //     : '')
                    }
                    width="100%"
                    height={this.state.iFrameHeight / 2 + 'px'}
                    scrolling="no"
                    onLoad={this.hideSpinner6}
                  />
                </Spin>
              </Col>
            </Row>
          </div>
        )
      }
    }

    return (
      <AuthorizeComponent {...this.props} matching_name="honeytoastlivestream">
        <GeneralStyledContent>
          <div>
            <Row style={{ margin: '0px 0px 5px 0px' }}>
              <Col span={12}>
                <div className="semi-bold-16" style={{ color: 'black' }}>
                  <LangContext.Consumer>
                    {i18n => i18n.h.honeytoastlivestream}
                  </LangContext.Consumer>
                </div>
              </Col>
            </Row>
            <Row style={{ margin: '0px 0px 5px 0px' }}>
              <Col span={12}>
                <Button icon="filter" size="small" onClick={this.showModal}>
                  <LangContext.Consumer>
                    {i18n => i18n.f.filter}
                  </LangContext.Consumer>
                </Button>
              </Col>
              <Col span={12}>
                <Row gutter={2} type="flex" justify="end">
                  <Button
                    icon="fullscreen"
                    size="small"
                    onClick={this.goFull}
                    style={{ marginLeft: '5px' }}
                  >
                    <LangContext.Consumer>
                      {i18n => i18n.f.Fullscreen}
                    </LangContext.Consumer>
                  </Button>

                  <Button
                    icon="play-circle"
                    size="small"
                    onClick={evt => this.setWindowNum(1, evt)}
                  >
                    1
                  </Button>

                  <Button
                    icon="play-circle"
                    size="small"
                    style={{ marginLeft: '5px' }}
                    onClick={evt => this.setWindowNum(4, evt)}
                  >
                    4
                  </Button>
                </Row>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col
                span={6}
                style={{
                  margin: '0px 0px 0px 0px',
                  maxHeight: `${window.innerHeight - 150}px`,
                  overflowY: 'auto',
                }}
              >
                <Row>
                  <Col span={24}>
                    <ListVehicle
                      loadListVehicleHoneytoast={this.loadListVehicleHoneytoast}
                      vehicleListHoneytoast={vehicleListHoneytoast}
                      videoLoading={videoLoading}
                      loadVehicleSelect={this.loadVehicleSelect}
                      vehicle_select={this.state.vehicle_select}
                      allimei={this.state.allimei}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={18}>
                <Fullscreen
                  enabled={this.state.isFull}
                  onChange={isFull => this.onchangeFullscreen(isFull)}
                >
                  {iframe}
                </Fullscreen>
              </Col>
            </Row>
          </div>
          <Filter
            division={this.state.division}
            department={this.state.department}
            section={this.state.section}
            plant={this.state.plant}
            handleDivisionChange={this.handleDivisionChange}
            handleDepartmentChange={this.handleDepartmentChange}
            handleSectionChange={this.handleSectionChange}
            handlePlantChange={this.handlePlantChange}
            selectedDivision={this.state.selectedDivision}
            selectedDepartment={this.state.selectedDepartment}
            selectedSection={this.state.selectedSection}
            selectedPlant={this.state.getIdPlant}
            divisionLoading={this.state.divisionLoading}
            departmentLoading={this.state.departmentLoading}
            sectionLoading={this.state.sectionLoading}
            plantLoading={this.state.plantLoading}
            visible={this.state.visible}
            onOk={this.handleOk}
            loadVehicle={this.loadVehicle}
            vehicleLists={vehicleLists}
            onCancel={this.handleCancel}
          />
        </GeneralStyledContent>
      </AuthorizeComponent>
    )
  }
}

const mapStateToProps = ({
  honeytoaststream,
  monitoringState,
  auth,
  auth: { profile },
}) => ({
  monitoringState,
  auth,
  profile,
  honeytoaststream,
})

const mapDispatchToProps = {
  loadListVehicleHoneytoast: actions.loadListVehicleHoneytoast.request,
  loadVehicle: actions.loadVehicle.request,
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
