import {
  CREATE_PERMISSION,
  UPDATE_PERMISSION,
  LOAD_PERMISSION,
  REMOVE_LISTS,
  LOAD_PERMISSION_DETAIL,
} from './actions'

const initialState = {
  lists: [],
  total: 1,
  loading: false,
  detailLoading: false,
  draw: -1,
  selectedPermission: {
    permission_id: null,
    name: '',
    matching_name: '',
    menu_hierarchy: '',
    description: '',
    active: true,
    view_permission: false,
    add_permission: false,
    edit_permission: false,
    delete_permission: false,
    special_1_permission: false,
    special_2_permission: false,
    special_3_permission: false,
    special_4_permission: false,
    special_1_name: '',
    special_2_name: '',
    special_3_name: '',
    special_4_name: '',
    usergroup: [],
  },

  count_group: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState
    case LOAD_PERMISSION.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        loading: loading,
      }
    case LOAD_PERMISSION.SUCCESS:
      const { page } = action.payload
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * 10
      }
      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }
    case LOAD_PERMISSION_DETAIL.REQUEST:
      return {
        ...state,
        detailLoading: true,
        count_group: {},
      }
    case LOAD_PERMISSION_DETAIL.SUCCESS:
      return {
        ...state,
        selectedPermission: action.payload.permission.data,
        count_group: action.payload.permission.count_group,
        detailLoading: false,
      }
    case CREATE_PERMISSION.SUCCESS:
      const { res } = action.payload
      return {
        ...state,
        selectedPermission: {
          ...state.selectedPermission,
          permission_id: res.id,
        },
        permission_id: res.id,
        detailLoading: false,
      }
    case UPDATE_PERMISSION.REQUEST:
      return {
        ...state,
        detailLoading: true,
      }
    case UPDATE_PERMISSION.SUCCESS:
      if (action.payload.res.status == 'fail')
        return {
          ...state,
          detailLoading: false,
        }
      return {
        ...state,
        detailLoading: false,
        selectedPermission: initialState.selectedPermission,
      }
    default:
      return state
  }
}
