import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { WebContentStyled } from '../../../styled/common-styled'
import { Row, Col, Collapse, Card, Icon, Breadcrumb, Button, Radio } from 'antd'
import DashBoard from './Dashboard/DashBoard'
import Monitoring from './Monitoring/Monitoring'
import Report from './Report/Report'
import Management from './Management/Management'
import MasterData from './MasterData/MasterData'
import Delivery from './Delivery/Delivery'
import Help from './Help/Help'
import Setting from './SettingDashboard/Setting'
import LangContext from 'modules/shared/context/langContext'

const checkMenuVisibility = (
  web_use,
  web_endpoint,
  matching_name,
  permissions,
) => {
  let getMatchingName = ''
  if (web_use != 'undefined' && web_use != '' && web_use == web_endpoint) {
    getMatchingName = matching_name
  } else if (web_use != 'undefined' && web_use == '') {
    getMatchingName = matching_name
  } else {
    getMatchingName = ''
  }
  let matchingNameArr = []
  matchingNameArr.push(getMatchingName)
  if (
    typeof permissions == 'undefined' ||
    typeof permissions == 'string' ||
    permissions == []
  )
    return true

  let hideMenu = true
  for (let i = 0; i < matchingNameArr.length; i++) {
    let menu = matchingNameArr[i]

    let canAccess = permissions.find(ele => {
      return ele.matching_name == menu && ele.can_view == 1
    })

    if (typeof canAccess != 'undefined') {
      hideMenu = false
    }
  }

  return hideMenu
}

class IndexNew extends Component {
  constructor(props) {
    super(props)
    this.state = {
      breadcrumbSubText: '',
      headerText: 'Sitemap',
      breadcrumbText: '',
      web_endpoint: '',
      detailDashboard: false,
      detailMonitoring: false,
      detailReport: false,
      detailManagement: false,
      detailMasterData: false,
      detailDelivery: false,
      detailHelp: false,
      detailSetting: false,
      mainDashboard: true,
      mainMonitoring: true,
      mainReport: true,
      mainManagement: true,
      mainMasterData: true,
      mainDelivery: true,
      mainHelp: true,
      mainSetting: true,
      btnBackward: false,
      dashboardObj: [
        // {
        //   matchingName: 'concreteDeliveryManagerDashboard',
        //   web_use: '',
        // },
        {
          matchingName: 'smartDispatching',
          web_use: '',
        },
        {
          matchingName: 'truckstatusdashboard',
          web_use: '',
        },
        {
          matchingName: 'notifyTheTruckInCorrectPoint',
          web_use: '',
        },
        {
          matchingName: 'SafetyDashboard',
          web_use: '',
        },
        {
          matchingName: 'gpsPerformanceDashboard',
          web_use: '',
        },
        {
          matchingName: 'deliveryDashboard',
          web_use: 'isupply',
        },
        {
          matchingName: 'transportationPerformanceDashboard',
          web_use: 'isupply',
        },
        {
          matchingName: 'theStatusOfVehiclesThatReceiveDailyJobs',
          web_use: 'isupply',
        },
        {
          matchingName: 'isupplyManageQueue',
          web_use: 'isupply',
        },
        {
          matchingName: 'precastDashboard',
          web_use: '',
        },
        {
          matchingName: 'multiDrop',
          web_use: '',
        },
        {
          matchingName: 'alert_alarm_dashboard',
          web_use: '',
        },
        {
          matchingName: 'eventMap',
          web_use: '',
        },
        {
          matchingName: 'driverKpiDashboard',
          web_use: '',
        },
      ],
      monitoringObj: [
        {
          matchingName: 'replay',
          web_use: '',
        },
        {
          matchingName: 'honeytoastlivestream',
          web_use: '',
        },
        {
          matchingName: 'honeytoastplayback',
          web_use: '',
        },
        {
          matchingName: 'honeytoastdownloadfile',
          web_use: '',
        },
        {
          matchingName: 'video',
          web_use: '',
        },
        {
          matchingName: 'mdvrdownloadfile',
          web_use: '',
        },
        {
          matchingName: 'summary_dashboard',
          web_use: '',
        },
        {
          matchingName: 'booking_overview_dashboard',
          web_use: '',
        },
        {
          matchingName: 'cashing_overview_dahsboar',
          web_use: '',
        },
      ],
      reportObj: [
        {
          matchingName: 'schedulereport',
          web_use: '',
        },
        {
          matchingName: 'TruckcurrentlocationReport',
          web_use: '',
        },
        {
          matchingName: 'NumberofdaysworkedReport',
          web_use: '',
        },
        {
          matchingName: 'DrivingdistanceReport',
          web_use: '',
        },
        {
          matchingName: 'DriverinformationReport',
          web_use: '',
        },
        {
          matchingName: 'theDriverIsIicenseReportHasExpired',
          web_use: '',
        },
        {
          matchingName: 'LoginhistoryReport',
          web_use: '',
        },
        {
          matchingName: 'GpsstatusReport',
          web_use: '',
        },
        {
          matchingName: 'TrackingdataReport',
          web_use: '',
        },
        {
          matchingName: 'dailyDrivingBehaviorReport',
          web_use: '',
        },
        {
          matchingName: 'reportCardSwipeBehaviorBeforeDrivingDaily',
          web_use: '',
        },
        {
          matchingName: 'RiskbehaviourReport',
          web_use: '',
        },
        {
          matchingName: 'DrivinglicensescanlogReport',
          web_use: '',
        },
        {
          matchingName: 'DrivinglogReport',
          web_use: '',
        },
        {
          matchingName: 'ParkingReport',
          web_use: '',
        },
        {
          matchingName: 'reportSummarizingTheNumberOfOffSiteParking',
          web_use: '',
        },
        {
          matchingName: 'VehicleinareaReport',
          web_use: '',
        },
        {
          matchingName: 'DrivingoverlimitReport',
          web_use: '',
        },
        {
          matchingName: 'TruckusageReport',
          web_use: '',
        },
        {
          matchingName: 'DozeoffriskReport',
          web_use: '',
        },
        {
          matchingName: 'dailyCarStatusReport',
          web_use: '',
        },
        {
          matchingName: 'SpeedoverlimitsummaryReport',
          web_use: '',
        },
        {
          matchingName: 'SummaryKPIReport',
          web_use: '',
        },
        {
          matchingName: 'TripbyengineoffonReport',
          web_use: '',
        },
        {
          matchingName: 'BehaviordrivingReport',
          web_use: '',
        },
        {
          matchingName: 'BehaviordrivingbycompanyReport',
          web_use: '',
        },
        {
          matchingName: 'ScoresummaryReport',
          web_use: '',
        },
        {
          matchingName: 'SafetyEventReport',
          web_use: '',
        },
        {
          matchingName: 'alarmAndAlertReportClassifiedByDailyCarRegistration',
          web_use: '',
        },
        {
          matchingName: 'DailyAlarmAndAlertReportByDriver',
          web_use: '',
        },
        {
          matchingName: 'alarmAndAlertReportClassifiedByYearlyCarRegistration',
          web_use: '',
        },
        {
          matchingName: 'reportAlarmFrequencyClassifiedByDailyDriver',
          web_use: '',
        },
        {
          matchingName: 'top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod',
          web_use: '',
        },
        {
          matchingName: 'TruckengineonReport',
          web_use: '',
        },
        {
          matchingName: 'TrucknotuseReport',
          web_use: '',
        },
        {
          matchingName: 'TruckengineoffReport',
          web_use: '',
        },
        {
          matchingName: 'VehicleperformanceconcludeReport',
          web_use: '',
        },
        {
          matchingName: 'drivingSummary',
          web_use: '',
        },
        {
          matchingName: 'concreteTrip',
          web_use: '',
        },
        {
          matchingName: 'deliveryReport',
          web_use: 'isupply',
        },
        {
          matchingName: 'weighingHistoryReport',
          web_use: 'isupply',
        },
        {
          matchingName: 'floorWallDelivery',
          web_use: '',
        },
        {
          matchingName: 'distancereport',
          web_use: '',
        },
        {
          matchingName: 'TripsummaryReport',
          web_use: '',
        },
        {
          matchingName: 'DailyReport',
          web_use: '',
        },
        {
          matchingName: 'TripsumbylocationReport',
          web_use: '',
        },
        {
          matchingName: 'TripsumbyvehReport',
          web_use: '',
        },
        {
          matchingName: 'EventReport',
          web_use: '',
        },
        {
          matchingName: 'RefuelReport',
          web_use: '',
        },
        {
          matchingName: 'fuelremainrateReport',
          web_use: '',
        },
        {
          matchingName: 'FueluseReport',
          web_use: '',
        },
        {
          matchingName: 'gpsVendorPayingSummaryReport',
          web_use: '',
        },
        {
          matchingName: 'partnerPerformReport',
          web_use: '',
        },
        {
          matchingName: 'gpsVendorPayingReport',
          web_use: '',
        },
        {
          matchingName: 'repairingReport',
          web_use: '',
        },
        {
          matchingName: 'gpsReportLostContact',
          web_use: '',
        },
        {
          matchingName: 'gpsDisconnectOnTrip',
          web_use: '',
        },
        {
          matchingName: 'distance_report_1',
          web_use: '',
        },
        {
          matchingName: 'InsuranceReport',
          web_use: '',
        },
      ],
      managementObj: [
        {
          matchingName: 'user',
          web_use: '',
        },
        {
          matchingName: 'userGroup',
          web_use: '',
        },
        {
          matchingName: 'dataVisibility',
          web_use: '',
        },
        {
          matchingName: 'vehicleSharing',
          web_use: '',
        },
        {
          matchingName: 'shareLocation',
          web_use: '',
        },
        {
          matchingName: 'partnerCar',
          web_use: '',
        },
        {
          matchingName: 'routemaster',
          web_use: '',
        },
        {
          matchingName: 'isupplyManageVehicle',
          web_use: 'isupply',
        },
      ],
      masterdataObj: [
        {
          matchingName: 'location',
          web_use: '',
        },
        {
          matchingName: 'zone',
          web_use: '',
        },
        {
          matchingName: 'vehicleBlacklist',
          web_use: 'isupply',
        },
        {
          matchingName: 'driverBlacklist',
          web_use: 'isupply',
        },
        {
          matchingName: 'nearByLocation',
          web_use: '',
        },
        {
          matchingName: 'locationGroup',
          web_use: '',
        },
        {
          matchingName: 'siteGroup',
          web_use: '',
        },
        {
          matchingName: 'vehicle_type',
          web_use: '',
        },
        {
          matchingName: 'vehicle',
          web_use: '',
        },
        {
          matchingName: 'vehicleGroup',
          web_use: '',
        },
        {
          matchingName: 'chauffeur',
          web_use: '',
        },
        {
          matchingName: 'masterDataMatrial',
          web_use: 'isupply',
        },
        {
          matchingName: 'comparativeRawMaterials',
          web_use: 'isupply',
        },
        {
          matchingName: 'requestMaterial',
          web_use: 'isupply',
        },
        {
          matchingName: 'plantMiningContract',
          web_use: 'isupply',
        },
        {
          matchingName: 'isupplySystemKey',
          web_use: 'isupply',
        },
        {
          matchingName: 'informationAboutTheCarRunWall',
          web_use: '',
        },
        {
          matchingName: 'informationAboutTheCarRunFloor',
          web_use: '',
        },
        {
          matchingName: 'product',
          web_use: '',
        },
        {
          matchingName: 'uom',
          web_use: 'undefined',
        },
        {
          matchingName: 'customer',
          web_use: '',
        },
        {
          matchingName: 'permission',
          web_use: '',
        },
        {
          matchingName: 'maintenance',
          web_use: '',
        },
        {
          matchingName: 'reFuelTitle',
          web_use: '',
        },
        {
          matchingName: 'company',
          web_use: '',
        },
        {
          matchingName: 'sendemail',
          web_use: '',
        },
      ],
      deliveryObj: [
        {
          matchingName: 'isupplyManageQueue',
          web_use: '',
        },
        {
          matchingName: 'trip',
          web_use: '',
        },
        {
          matchingName: 'routemaster',
          web_use: '',
        },
      ],
      helpObj: [
        {
          matchingName: 'requestACertificate',
          web_use: '',
        },
        {
          matchingName: 'maintenancedevice',
          web_use: '',
        },
        {
          matchingName: 'servicerepairdevice',
          web_use: '',
        },
        {
          matchingName: 'servicemovedevice',
          web_use: '',
        },
      ],
      settingObj: [
        {
          matchingName: 'cpacPolicy',
          web_use: '',
        },
        {
          matchingName: 'policy',
          web_use: '',
        },
        {
          matchingName: 'linenotify',
          web_use: '',
        },
      ],
      monitoringHoneyToastObj: [
        {
          matchingName: 'honeytoastlivestream',
          web_use: '',
        },
        {
          matchingName: 'honeytoastplayback',
          web_use: '',
        },
        {
          matchingName: 'honeytoastdownloadfile',
          web_use: '',
        },
      ],
      monitoringMDVRObj: [
        {
          matchingName: 'video',
          web_use: '',
        },
        {
          matchingName: 'mdvrdownloadfile',
          web_use: '',
        },
        {
          matchingName: 'summary_dashboard',
          web_use: '',
        },
        {
          matchingName: 'booking_overview_dashboard',
          web_use: '',
        },
        {
          matchingName: 'cashing_overview_dahsboar',
          web_use: '',
        },
      ],
      deliveryTripObj: [
        {
          matchingName: 'trip',
          web_use: '',
        },
        {
          matchingName: 'routemaster',
          web_use: '',
        },
      ],
      masterdataLocationObj: [
        {
          matchingName: 'location',
          web_use: '',
        },
        {
          matchingName: 'zone',
          web_use: '',
        },
        {
          matchingName: 'vehicleBlacklist',
          web_use: 'isupply',
        },
        {
          matchingName: 'driverBlacklist',
          web_use: 'isupply',
        },
        {
          matchingName: 'nearByLocation',
          web_use: '',
        },
        {
          matchingName: 'locationGroup',
          web_use: '',
        },
        {
          matchingName: 'siteGroup',
          web_use: '',
        },
      ],
      masterdataVehicleObj: [
        {
          matchingName: 'vehicle_type',
          web_use: '',
        },
        {
          matchingName: 'vehicle',
          web_use: '',
        },
        {
          matchingName: 'vehicleGroup',
          web_use: '',
        },
      ],
      masterdataChauffeurObj: [
        {
          matchingName: 'chauffeur',
          web_use: '',
        },
      ],
      masterdataPrecastObj: [
        {
          matchingName: 'informationAboutTheCarRunWall',
          web_use: '',
        },
        {
          matchingName: 'informationAboutTheCarRunFloor',
          web_use: '',
        },
      ],
      masterdataMaterialObj: [
        {
          matchingName: 'masterDataMatrial',
          web_use: 'isupply',
        },
        {
          matchingName: 'comparativeRawMaterials',
          web_use: 'isupply',
        },
        {
          matchingName: 'requestMaterial',
          web_use: 'isupply',
        },
        {
          matchingName: 'plantMiningContract',
          web_use: 'isupply',
        },
        {
          matchingName: 'isupplySystemKey',
          web_use: 'isupply',
        },
      ],
      reportGeneralObj: [
        {
          matchingName: 'TruckcurrentlocationReport',
          web_use: '',
        },
        {
          matchingName: 'driverKpiDashboard',
          web_use: '',
        },
        {
          matchingName: 'NumberofdaysworkedReport',
          web_use: '',
        },
        {
          matchingName: 'DrivingdistanceReport',
          web_use: '',
        },
        {
          matchingName: 'DriverinformationReport',
          web_use: '',
        },
        {
          matchingName: 'theDriverIsIicenseReportHasExpired',
          web_use: '',
        },
        {
          matchingName: 'LoginhistoryReport',
          web_use: '',
        },
        {
          matchingName: 'GpsstatusReport',
          web_use: '',
        },
        {
          matchingName: 'TrackingdataReport',
          web_use: '',
        },
      ],
      reportBehaviorObj: [
        {
          matchingName: 'dailyDrivingBehaviorReport',
          web_use: '',
        },
        {
          matchingName: 'reportCardSwipeBehaviorBeforeDrivingDaily',
          web_use: '',
        },
        {
          matchingName: 'RiskbehaviourReport',
          web_use: '',
        },
        {
          matchingName: 'DrivinglicensescanlogReport',
          web_use: '',
        },
        {
          matchingName: 'DrivinglogReport',
          web_use: '',
        },
        {
          matchingName: 'ParkingReport',
          web_use: '',
        },
        {
          matchingName: 'reportSummarizingTheNumberOfOffSiteParking',
          web_use: '',
        },
        {
          matchingName: 'VehicleinareaReport',
          web_use: '',
        },
        {
          matchingName: 'DrivingoverlimitReport',
          web_use: '',
        },
        {
          matchingName: 'TruckusageReport',
          web_use: '',
        },
        {
          matchingName: 'DozeoffriskReport',
          web_use: '',
        },
        {
          matchingName: 'dailyCarStatusReport',
          web_use: '',
        },
        {
          matchingName: 'SpeedoverlimitsummaryReport',
          web_use: '',
        },
        {
          matchingName: 'SummaryKPIReport',
          web_use: '',
        },
        {
          matchingName: 'TripbyengineoffonReport',
          web_use: '',
        },
      ],
      reportAlertAndAlarmObj: [
        {
          matchingName: 'BehaviordrivingReport',
          web_use: '',
        },
        {
          matchingName: 'BehaviordrivingbycompanyReport',
          web_use: '',
        },
        {
          matchingName: 'ScoresummaryReport',
          web_use: '',
        },
        {
          matchingName: 'SafetyEventReport',
          web_use: '',
        },
        {
          matchingName: 'alarmAndAlertReportClassifiedByDailyCarRegistration',
          web_use: '',
        },
        {
          matchingName: 'DailyAlarmAndAlertReportByDriver',
          web_use: '',
        },
        {
          matchingName: 'alarmAndAlertReportClassifiedByYearlyCarRegistration',
          web_use: '',
        },
        {
          matchingName: 'reportAlarmFrequencyClassifiedByDailyDriver',
          web_use: '',
        },
        {
          matchingName: 'top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod',
          web_use: '',
        },
      ],
      reportEfficiencyObj: [
        {
          matchingName: 'TruckengineonReport',
          web_use: '',
        },
        {
          matchingName: 'TrucknotuseReport',
          web_use: '',
        },
        {
          matchingName: 'TruckengineoffReport',
          web_use: '',
        },
        {
          matchingName: 'VehicleperformanceconcludeReport',
          web_use: '',
        },
        {
          matchingName: 'drivingSummary',
          web_use: '',
        },
      ],
      reportTripObj: [
        {
          matchingName: 'unauthorizeunload',
          web_use: '',
        },
        {
          matchingName: 'concreteTrip',
          web_use: '',
        },
        {
          matchingName: 'deliveryReport',
          web_use: 'isupply',
        },
        {
          matchingName: 'weighingHistoryReport',
          web_use: 'isupply',
        },
        {
          matchingName: 'floorWallDelivery',
          web_use: '',
        },
        {
          matchingName: 'distancereport',
          web_use: '',
        },
        {
          matchingName: 'TripsummaryReport',
          web_use: '',
        },
        {
          matchingName: 'DailyReport',
          web_use: '',
        },
        {
          matchingName: 'TripsumbylocationReport',
          web_use: '',
        },
        {
          matchingName: 'TripsumbyvehReport',
          web_use: '',
        },
      ],
      reportPolicyObj: [
        {
          matchingName: 'EventReport',
          web_use: '',
        },
      ],
      reportEnergyObj: [
        {
          matchingName: 'RefuelReport',
          web_use: '',
        },
        {
          matchingName: 'fuelremainrateReport',
          web_use: '',
        },
        {
          matchingName: 'FueluseReport',
          web_use: '',
        },
      ],
      reportCostObj: [
        {
          matchingName: 'gpsVendorPayingSummaryReport',
          web_use: '',
        },
        {
          matchingName: 'partnerPerformReport',
          web_use: '',
        },
        {
          matchingName: 'gpsVendorPayingReport',
          web_use: '',
        },
      ],
      reportMaintenanceDeviceObj: [
        {
          matchingName: 'repairingReport',
          web_use: '',
        },
        {
          matchingName: 'gpsReportLostContact',
          web_use: '',
        },
        {
          matchingName: 'gpsDisconnectOnTrip',
          web_use: '',
        },
        {
          matchingName: 'distance_report_1',
          web_use: '',
        },
      ],
      reportInsureObj: [
        {
          matchingName: 'InsuranceReport',
          web_use: '',
        },
      ],
    }
  }
  componentDidMount() {
    this.setState({
      web_endpoint: process.env.REACT_APP_WEB_ENDPOINT,
    })
  }

  checkSubMenuVisibility = obj => {
    // console.log('obj', obj)
    let showMenu = false
    if (obj.length != 0) {
      let canAccess = obj.map(ele => {
        return checkMenuVisibility(
          ele.web_use,
          this.state.web_endpoint,
          ele.matchingName,
          this.props.profile.permissions,
        )
      })
      if (canAccess.includes(false)) {
        showMenu = true
      }
      //   console.log('Access', canAccess)
      return showMenu
    } else {
      return showMenu
    }
  }

  handleDashboard = () => {
    this.setState({
      detailDashboard: true,
      detailMonitoring: false,
      detailReport: false,
      detailManagement: false,
      detailMasterData: false,
      detailDelivery: false,
      detailHelp: false,
      detailSetting: false,
      mainDashboard: false,
      mainMonitoring: false,
      mainReport: false,
      mainManagement: false,
      mainMasterData: false,
      mainDelivery: false,
      mainHelp: false,
      mainSetting: false,
      btnBackward: true,
      breadcrumbText: (
        <LangContext.Consumer>{i18n => i18n.d.dashboard}</LangContext.Consumer>
      ),
      headerText: (
        <LangContext.Consumer>{i18n => i18n.d.dashboard}</LangContext.Consumer>
      ),
    })
  }

  handleMonitoring = () => {
    this.setState({
      detailDashboard: false,
      detailMonitoring: true,
      detailReport: false,
      detailManagement: false,
      detailMasterData: false,
      detailDelivery: false,
      detailHelp: false,
      detailSetting: false,
      mainDashboard: false,
      mainMonitoring: false,
      mainReport: false,
      mainManagement: false,
      mainMasterData: false,
      mainDelivery: false,
      mainHelp: false,
      mainSetting: false,
      btnBackward: true,
      breadcrumbText: (
        <LangContext.Consumer>{i18n => i18n.m.monitoring}</LangContext.Consumer>
      ),
      headerText: (
        <LangContext.Consumer>{i18n => i18n.m.monitoring}</LangContext.Consumer>
      ),
    })
  }

  handleReport = () => {
    this.setState({
      detailDashboard: false,
      detailMonitoring: false,
      detailReport: true,
      detailManagement: false,
      detailMasterData: false,
      detailDelivery: false,
      detailHelp: false,
      detailSetting: false,
      mainDashboard: false,
      mainMonitoring: false,
      mainReport: false,
      mainManagement: false,
      mainMasterData: false,
      mainDelivery: false,
      mainHelp: false,
      mainSetting: false,
      btnBackward: true,
      breadcrumbText: (
        <LangContext.Consumer>{i18n => i18n.r.report}</LangContext.Consumer>
      ),
      headerText: (
        <LangContext.Consumer>{i18n => i18n.r.report}</LangContext.Consumer>
      ),
    })
  }

  handleManagement = () => {
    this.setState({
      detailDashboard: false,
      detailMonitoring: false,
      detailReport: false,
      detailManagement: true,
      detailMasterData: false,
      detailDelivery: false,
      detailHelp: false,
      detailSetting: false,
      mainDashboard: false,
      mainMonitoring: false,
      mainReport: false,
      mainManagement: false,
      mainMasterData: false,
      mainDelivery: false,
      mainHelp: false,
      mainSetting: false,
      btnBackward: true,
      breadcrumbText: (
        <LangContext.Consumer>
          {i18n => i18n.m.management_menu}
        </LangContext.Consumer>
      ),
      headerText: (
        <LangContext.Consumer>
          {i18n => i18n.m.management_menu}
        </LangContext.Consumer>
      ),
    })
  }

  handleMasterData = () => {
    this.setState({
      detailDashboard: false,
      detailMonitoring: false,
      detailReport: false,
      detailManagement: false,
      detailMasterData: true,
      detailDelivery: false,
      detailHelp: false,
      detailSetting: false,
      mainDashboard: false,
      mainMonitoring: false,
      mainReport: false,
      mainManagement: false,
      mainMasterData: false,
      mainDelivery: false,
      mainHelp: false,
      mainSetting: false,
      btnBackward: true,
      breadcrumbText: (
        <LangContext.Consumer>{i18n => i18n.m.masterData}</LangContext.Consumer>
      ),
      headerText: (
        <LangContext.Consumer>{i18n => i18n.m.masterData}</LangContext.Consumer>
      ),
    })
  }

  handleDelivery = () => {
    this.setState({
      detailDashboard: false,
      detailMonitoring: false,
      detailReport: false,
      detailManagement: false,
      detailMasterData: false,
      detailDelivery: true,
      detailHelp: false,
      detailSetting: false,
      mainDashboard: false,
      mainMonitoring: false,
      mainReport: false,
      mainManagement: false,
      mainMasterData: false,
      mainDelivery: false,
      mainHelp: false,
      mainSetting: false,
      btnBackward: true,
      breadcrumbText: (
        <LangContext.Consumer>{i18n => i18n.d.deliver}</LangContext.Consumer>
      ),
      headerText: (
        <LangContext.Consumer>{i18n => i18n.d.deliver}</LangContext.Consumer>
      ),
    })
  }

  handleHelp = () => {
    this.setState({
      detailDashboard: false,
      detailMonitoring: false,
      detailReport: false,
      detailManagement: false,
      detailMasterData: false,
      detailDelivery: false,
      detailHelp: true,
      detailSetting: false,
      mainDashboard: false,
      mainMonitoring: false,
      mainReport: false,
      mainManagement: false,
      mainMasterData: false,
      mainDelivery: false,
      mainHelp: false,
      mainSetting: false,
      btnBackward: true,
      breadcrumbText: (
        <LangContext.Consumer>{i18n => i18n.h.help_menu}</LangContext.Consumer>
      ),
      headerText: (
        <LangContext.Consumer>{i18n => i18n.h.help_menu}</LangContext.Consumer>
      ),
    })
  }

  handleSetting = () => {
    this.setState({
      detailDashboard: false,
      detailMonitoring: false,
      detailReport: false,
      detailManagement: false,
      detailMasterData: false,
      detailDelivery: false,
      detailHelp: false,
      detailSetting: true,
      mainDashboard: false,
      mainMonitoring: false,
      mainReport: false,
      mainManagement: false,
      mainMasterData: false,
      mainDelivery: false,
      mainHelp: false,
      mainSetting: false,
      btnBackward: true,
      breadcrumbText: (
        <LangContext.Consumer>{i18n => i18n.s.setting}</LangContext.Consumer>
      ),
      headerText: (
        <LangContext.Consumer>{i18n => i18n.s.setting}</LangContext.Consumer>
      ),
    })
  }

  handleBackward = () => {
    this.setState({
      detailDashboard: false,
      detailMonitoring: false,
      detailReport: false,
      detailManagement: false,
      detailMasterData: false,
      detailDelivery: false,
      detailHelp: false,
      detailSetting: false,
      mainDashboard: true,
      mainMonitoring: true,
      mainReport: true,
      mainManagement: true,
      mainMasterData: true,
      mainDelivery: true,
      mainHelp: true,
      mainSetting: true,
      btnBackward: false,
      breadcrumbText: '',
      headerText: 'Sitemap',
      breadcrumbSubText: '',
    })
  }

  getBreadcrumbSubText = text => {
    this.setState({
      breadcrumbSubText: text,
    })
  }

  render() {
    const { permissions } = this.props.profile
    return (
      <WebContentStyled>
        <div
          style={{
            fontSize: 26,
            marginBottom: 10,
            marginLeft: 0,
            fontWeight: 600,
            color: '#172B4D',
          }}
        >
          {this.state.btnBackward ? (
            <span className="left__button__web__content">
              <Button
                shape="circle"
                onClick={() => {
                  this.handleBackward()
                }}
                size="large"
              >
                <Icon type="left" style={{ color: 'black', paddingTop: 6 }} />
              </Button>
            </span>
          ) : (
            <></>
          )}
          {this.state.headerText}
        </div>

        <div style={{ marginLeft: 0, marginRight: 140 }}>
          <Row gutter={[32, 32]}>
            {/* Dashboard */}
            {this.checkSubMenuVisibility(this.state.dashboardObj) ? (
              <>
                {this.state.mainDashboard ? (
                  <Col span={4}>
                    <div
                      onClick={() => {
                        this.handleDashboard()
                      }}
                    >
                      <Card className="card__web__content">
                        <p className="main__text__card__web__content">
                          <Icon
                            type="dot-chart"
                            style={{ fontSize: '1.2rem' }}
                            className="icon__card__web__content"
                          />{' '}
                          <span className="color__text__card__web__content">
                            <LangContext.Consumer>
                              {i18n => i18n.d.dashboard}
                            </LangContext.Consumer>
                          </span>
                        </p>
                      </Card>
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {this.state.detailDashboard ? (
              <DashBoard
                permissions={permissions}
                checkMenuVisibility={checkMenuVisibility}
                web_endpoint={this.state.web_endpoint}
              />
            ) : (
              <></>
            )}
            {/* Monitoring */}
            {this.checkSubMenuVisibility(this.state.monitoringObj) ? (
              <>
                {this.state.mainMonitoring ? (
                  <Col span={4}>
                    <div
                      onClick={() => {
                        this.handleMonitoring()
                      }}
                    >
                      <Card className="card__web__content">
                        <p className="main__text__card__web__content">
                          <Icon
                            type="car"
                            style={{ fontSize: '1.2rem' }}
                            className="icon__card__web__content"
                          />{' '}
                          <span className="color__text__card__web__content">
                            <LangContext.Consumer>
                              {i18n => i18n.m.monitoring}
                            </LangContext.Consumer>
                          </span>
                        </p>
                      </Card>
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            {this.state.detailMonitoring ? (
              <Monitoring
                permissions={permissions}
                checkMenuVisibility={checkMenuVisibility}
                web_endpoint={this.state.web_endpoint}
                checkSubMenuVisibility={this.checkSubMenuVisibility}
                monitoringHoneyToastObj={this.state.monitoringHoneyToastObj}
                monitoringMDVRObj={this.state.monitoringMDVRObj}
                getBreadcrumbSubText={this.getBreadcrumbSubText}
              />
            ) : (
              <></>
            )}
            {/* Report */}
            {this.checkSubMenuVisibility(this.state.reportObj) ? (
              <>
                {this.state.mainReport ? (
                  <Col span={4}>
                    <div
                      onClick={() => {
                        this.handleReport()
                      }}
                    >
                      <Card className="card__web__content">
                        <p className="main__text__card__web__content">
                          <Icon
                            type="exception"
                            style={{ fontSize: '1.2rem' }}
                            className="icon__card__web__content"
                          />{' '}
                          <span className="color__text__card__web__content">
                            <LangContext.Consumer>
                              {i18n => i18n.r.report}
                            </LangContext.Consumer>
                          </span>
                        </p>
                      </Card>
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            {this.state.detailReport ? (
              <Report
                permissions={permissions}
                checkMenuVisibility={checkMenuVisibility}
                web_endpoint={this.state.web_endpoint}
                checkSubMenuVisibility={this.checkSubMenuVisibility}
                reportGeneralObj={this.state.reportGeneralObj}
                reportBehaviorObj={this.state.reportBehaviorObj}
                reportAlertAndAlarmObj={this.state.reportAlertAndAlarmObj}
                reportEfficiencyObj={this.state.reportEfficiencyObj}
                reportTripObj={this.state.reportTripObj}
                reportPolicyObj={this.state.reportPolicyObj}
                reportEnergyObj={this.state.reportEnergyObj}
                reportCostObj={this.state.reportCostObj}
                reportMaintenanceDeviceObj={
                  this.state.reportMaintenanceDeviceObj
                }
                reportInsureObj={this.state.reportInsureObj}
                getBreadcrumbSubText={this.getBreadcrumbSubText}
              />
            ) : (
              <></>
            )}
            {/* Management */}
            {this.checkSubMenuVisibility(this.state.managementObj) ? (
              <>
                {this.state.mainManagement ? (
                  <Col span={4}>
                    <div
                      onClick={() => {
                        this.handleManagement()
                      }}
                    >
                      <Card className="card__web__content">
                        <p className="main__text__card__web__content">
                          <Icon
                            type="appstore"
                            style={{ fontSize: '1.2rem' }}
                            className="icon__card__web__content"
                          />{' '}
                          <span className="color__text__card__web__content">
                            <LangContext.Consumer>
                              {i18n => i18n.m.management_menu}
                            </LangContext.Consumer>
                          </span>
                        </p>
                      </Card>
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            {this.state.detailManagement ? (
              <Management
                permissions={permissions}
                checkMenuVisibility={checkMenuVisibility}
                web_endpoint={this.state.web_endpoint}
              />
            ) : (
              <></>
            )}
            {/* MasterData */}
            {this.checkSubMenuVisibility(this.state.masterdataObj) ? (
              <>
                {this.state.mainMasterData ? (
                  <Col span={4}>
                    <div
                      onClick={() => {
                        this.handleMasterData()
                      }}
                    >
                      <Card className="card__web__content">
                        <p className="main__text__card__web__content">
                          <Icon
                            type="folder"
                            style={{ fontSize: '1.2rem' }}
                            className="icon__card__web__content"
                          />{' '}
                          <span className="color__text__card__web__content">
                            <LangContext.Consumer>
                              {i18n => i18n.m.masterData}
                            </LangContext.Consumer>
                          </span>
                        </p>
                      </Card>
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            {this.state.detailMasterData ? (
              <MasterData
                permissions={permissions}
                checkMenuVisibility={checkMenuVisibility}
                web_endpoint={this.state.web_endpoint}
                checkSubMenuVisibility={this.checkSubMenuVisibility}
                masterdataLocationObj={this.state.masterdataLocationObj}
                masterdataVehicleObj={this.state.masterdataVehicleObj}
                masterdataChauffeurObj={this.state.masterdataChauffeurObj}
                masterdataPrecastObj={this.state.masterdataPrecastObj}
                masterdataMaterialObj={this.state.masterdataMaterialObj}
                getBreadcrumbSubText={this.getBreadcrumbSubText}
              />
            ) : (
              <></>
            )}
            {/* Delivery */}
            {this.checkSubMenuVisibility(this.state.deliveryObj) ? (
              <>
                {this.state.mainDelivery ? (
                  <Col span={4}>
                    <div
                      onClick={() => {
                        this.handleDelivery()
                      }}
                    >
                      <Card className="card__web__content">
                        <p className="main__text__card__web__content">
                          <Icon
                            type="retweet"
                            style={{ fontSize: '1.2rem' }}
                            className="icon__card__web__content"
                          />{' '}
                          <span className="color__text__card__web__content">
                            {' '}
                            <LangContext.Consumer>
                              {i18n => i18n.d.deliver}
                            </LangContext.Consumer>
                          </span>
                        </p>
                      </Card>
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            {this.state.detailDelivery ? (
              <Delivery
                permissions={permissions}
                checkMenuVisibility={checkMenuVisibility}
                web_endpoint={this.state.web_endpoint}
                checkSubMenuVisibility={this.checkSubMenuVisibility}
                deliveryTripObj={this.state.deliveryTripObj}
                getBreadcrumbSubText={this.getBreadcrumbSubText}
              />
            ) : (
              <></>
            )}
            {/* Help */}
            {this.checkSubMenuVisibility(this.state.helpObj) ? (
              <>
                {this.state.mainHelp ? (
                  <Col span={4}>
                    <div
                      onClick={() => {
                        this.handleHelp()
                      }}
                    >
                      <Card className="card__web__content">
                        <p className="main__text__card__web__content">
                          <Icon
                            type="safety-certificate"
                            style={{ fontSize: '1.2rem' }}
                            className="icon__card__web__content"
                          />{' '}
                          <span className="color__text__card__web__content">
                            {' '}
                            <LangContext.Consumer>
                              {i18n => i18n.h.help_menu}
                            </LangContext.Consumer>
                          </span>
                        </p>
                      </Card>
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            {this.state.detailHelp ? (
              <Help
                permissions={permissions}
                checkMenuVisibility={checkMenuVisibility}
                web_endpoint={this.state.web_endpoint}
              />
            ) : (
              <></>
            )}
            {/* Setting */}
            {this.checkSubMenuVisibility(this.state.settingObj) ? (
              <>
                {this.state.mainSetting ? (
                  <Col span={4}>
                    <div
                      onClick={() => {
                        this.handleSetting()
                      }}
                    >
                      <Card className="card__web__content">
                        <p className="main__text__card__web__content">
                          <Icon
                            type="setting"
                            style={{ fontSize: '1.2rem' }}
                            className="icon__card__web__content"
                          />{' '}
                          <span className="color__text__card__web__content">
                            {' '}
                            <LangContext.Consumer>
                              {i18n => i18n.s.setting}
                            </LangContext.Consumer>
                          </span>
                        </p>
                      </Card>
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            {this.state.detailSetting ? (
              <Setting
                permissions={permissions}
                checkMenuVisibility={checkMenuVisibility}
                web_endpoint={this.state.web_endpoint}
              />
            ) : (
              <></>
            )}
          </Row>
        </div>
      </WebContentStyled>
    )
  }
}

const mapStateToProps = ({ auth: { profile } }) => ({
  profile,
})

export default connect(mapStateToProps, null)(IndexNew)
