import React, { Component } from 'react'
import { Modal, Button, Form, Row, Col, Transfer, Select } from 'antd'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Link } from 'react-router-dom'

import LangContext, { i18n } from 'modules/shared/context/langContext'

const { Option } = Select
const FormItem = Form.Item

class Filter extends Component {
  constructor(props) {
    super(props)
    this.Leftbtn = null
    this.Rightbtn = null
  }
  state = {
    vehicleList: [],
    vehicleSelect: [],
    selectedKeys: [],
  }

  componentDidMount() {
    const { loadVehicle } = this.props
    loadVehicle()
  }
  componentDidUpdate() {
    if (document.getElementById('finding-btn') !== null) {
      let btn = document
        .getElementById('finding-btn')
        .querySelectorAll('button')
      //   console.log('btn', btn)
      this.Leftbtn = btn[0]
      this.Rightbtn = btn[1]
    }
  }

  componentWillReceiveProps = nextProps => {
    const { vehicleLists } = nextProps
    const vehlist = []
    vehicleLists.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      }),
    )

    this.setState({ vehicleList: vehlist })
  }

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue('list_vehicle_id', targetKeys)
    this.setState({ vehicleSelect: targetKeys })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn.click()
        } else {
          this.Rightbtn.click()
        }
      },
    )
  }

  handlePreviewClick = (e, setFieldValue, handleSubmit) => {
    setFieldValue('orderBy', 'plate_no', false)
    setFieldValue('orderType', 'asc', false)
    handleSubmit()
  }

  render() {
    const { visible, onOk, onCancel, vehicleLists } = this.props

    return (
      <div>
        <div>
          <Formik
            initialValues={{
              list_vehicle_id: [],
            }}
            validate={values => {
              let errors = {}

              return errors
            }}
            validationSchema={yup.object().shape({
              // list_vehicle_id: yup.array().required("กรุณาเลือกรถ")
            })}
            onSubmit={values => {
              const loading = true
              onOk(values, loading)
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
              setFieldValue,
              isValidating,
            }) => (
              <Modal
                style={{ top: 20 }}
                title={
                  <LangContext.Consumer>
                    {i18n => i18n.f.filter}
                  </LangContext.Consumer>
                }
                visible={visible}
                onOk={value =>
                  this.handlePreviewClick(value, setFieldValue, handleSubmit)
                }
                onCancel={onCancel}
                okText={
                  <LangContext.Consumer>
                    {i18n => i18n.o.okay}
                  </LangContext.Consumer>
                }
                cancelText={
                  <LangContext.Consumer>
                    {i18n => i18n.c.cancel}
                  </LangContext.Consumer>
                }
                width={700}
              >
                <Row type="flex" justify="center">
                  <Form onSubmit={handleSubmit}>
                    <FormItem
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.d.division}
                        </LangContext.Consumer>
                      }
                    >
                      <Select
                        defaultValue={[]}
                        mode="multiple"
                        style={{ width: '100%' }}
                        onChange={val => {
                          this.divisionSelect.blur()
                          return this.props.handleDivisionChange(val)
                        }}
                        ref={select => (this.divisionSelect = select)}
                        value={this.props.selectedDivision}
                        loading={this.props.divisionLoading}
                      >
                        {this.props.division.map(ele => (
                          <Option value={`${ele.id}`} key={ele.id}>
                            {ele.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>
                    <FormItem
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.d.department}
                        </LangContext.Consumer>
                      }
                    >
                      <Select
                        defaultValue={[]}
                        mode="multiple"
                        style={{ width: '100%' }}
                        onChange={val => {
                          this.departmentSelect.blur()
                          return this.props.handleDepartmentChange(val)
                        }}
                        ref={select => (this.departmentSelect = select)}
                        value={this.props.selectedDepartment}
                        loading={this.props.departmentLoading}
                      >
                        {this.props.department.map(ele => (
                          <Option value={`${ele.id}`} key={ele.id}>
                            {ele.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>
                    <FormItem
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.s.section}
                        </LangContext.Consumer>
                      }
                    >
                      <Select
                        defaultValue={[]}
                        mode="multiple"
                        style={{ width: '100%' }}
                        onChange={val => {
                          this.sectionSelect.blur()
                          return this.props.handleSectionChange(val)
                        }}
                        ref={select => (this.sectionSelect = select)}
                        value={this.props.selectedSection}
                        loading={this.props.sectionLoading}
                      >
                        {this.props.section.map(ele => (
                          <Option value={`${ele.id}`} key={ele.id}>
                            {ele.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>
                    <FormItem
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.p.plants}
                        </LangContext.Consumer>
                      }
                    >
                      <Select
                        defaultValue={[]}
                        mode="multiple"
                        style={{ width: '100%' }}
                        onChange={(val, key) => {
                          this.plantSelect.blur()
                          return this.props.handlePlantChange(val, key)
                        }}
                        ref={select => (this.plantSelect = select)}
                        value={this.props.selectedPlant}
                        loading={this.props.plantLoading}
                      >
                        {this.props.plant.map(ele => (
                          <Option value={ele.name} key={ele.id}>
                            {ele.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <FormItem
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.v.vehicle}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.list_vehicle_id &&
                        errors.list_vehicle_id &&
                        'error'
                      }
                      help={touched.list_vehicle_id && errors.list_vehicle_id}
                    >
                      <div id="finding-btn">
                        <Transfer
                          titles={[
                            <LangContext.Consumer>
                              {i18n => i18n.i.itemLeft}
                            </LangContext.Consumer>,
                            <LangContext.Consumer>
                              {i18n => i18n.i.itemSelected}
                            </LangContext.Consumer>,
                          ]}
                          dataSource={this.state.vehicleList}
                          showSearch
                          listStyle={{
                            width: 250,
                            height: 300,
                          }}
                          selectedKeys={this.state.selectedKeys}
                          operations={['>>', '<<']}
                          targetKeys={this.state.vehicleSelect}
                          onSelectChange={this.handleSelectChange}
                          onChange={value =>
                            this.tranferChange(value, setFieldValue)
                          }
                          render={item => `${item.name}`}
                          footer={this.renderFooter}
                          filterOption={(inputValue, item) => {
                            return (
                              item.name
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            )
                          }}
                        />
                      </div>
                    </FormItem>
                  </Form>
                </Row>
              </Modal>
            )}
          />
        </div>
      </div>
    )
  }
}

export default Filter
